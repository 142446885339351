<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col>
              <h4>User Access</h4>
            </b-col>
          </b-row>

          <b-row class="h-100" v-if="state !== 'show'" align-h="center">
            <b-spinner label="Loading..."></b-spinner>
          </b-row>

          <b-form v-if="state === 'show'" @submit.stop.prevent="submit">
                       <div v-if="user!=null">
              <b-row>
                <b-col cols="2">
                  <H3>
                    {{ user.name }} {{ user.surname }}
                  </H3>
                  <h4>
                    {{ user.email }}
                  </h4>
                </b-col>
                <b-col cols="2"/>
                <b-col cols="3" v-if="selectedEntity!=null">
                  <H3>
                    {{ selectedEntity.name }} ({{ selectedEntity.code }})
                  </H3>
                </b-col>

              </b-row>
              <b-row>
                <b-col cols="4">
                  <b-form-group label="Entity Type">
                    <b-form-radio v-model="entityType" name="entitySelect" value="Customer">Customer</b-form-radio>
                    <b-form-radio v-model="entityType" name="entitySelect" value="Vendor">Vendor</b-form-radio>
                  </b-form-group>
                </b-col>
                <b-col cols="8">
                  <b-form-group id="email-input-group" :label="entityType+' Documents'" label-for="documentSelect">
                    <b-form-checkbox-group
                        id="documentSelect"
                        v-model="entityAccess.documentTypes"
                        :options="entityDocumentList"
                        name="documentSelect"
                    ></b-form-checkbox-group>
                  </b-form-group>

                </b-col>
                <b-col cols="4">
                  <b-form-group id="passwordConfirm-input-group" :label="entityType+ ' Search'"
                                label-for="passwordConfirm">
                    <b-form-input
                        id="entitySearch"
                        name="entitySearch"
                        v-model="entitySearch"
                        @keyup="searchEntity"
                        type="text"
                    ></b-form-input>

                  </b-form-group>

                </b-col>
              </b-row>


              <b-row>
                <b-col cols="12">

                  <b-table striped hover
                           sort-icon-left
                           :items="entityTableData.dataSource"
                           :fields="entityTableData.tableColumns"
                           :busy="entityTableData.isLoading"
                           @row-clicked="selectEntity"
                           :per-page="entityTableData.resultsPerPage"
                           id="EntityTable"
                           :current-page="entityTableData.currentPage">

                    <template #table-busy>
                      <div class="text-center my-2">
                        <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                      </div>
                    </template>

                  </b-table>
                  <b-row align-h="center">
                    <b-pagination
                        v-model="entityTableData.currentPage"
                        :total-rows="rows"
                        :per-page="entityTableData.resultsPerPage"
                        aria-controls="SearchUser"
                    ></b-pagination>
                  </b-row>


                </b-col>


              </b-row>

            </div>
            <hr class="mx-3">
            <b-row>
              <b-col>
                <div class="d-flex justify-content-end">
                  <div>
                    <b-button variant="outline-red" squared @click="goBackToSearch" class="ml-2">Cancel</b-button>
                  </div>
                  <div>
                    <b-button variant="primary" squared @click="submit()" class="ml-2" :disabled="!completed">
                      Save
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-form>

        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<style>
.primary {
  background-color: #ffcb14;
  color: #222222;
}

.primary:hover {
  color: #fff;
}
</style>
<script>
import {mapMutations} from "vuex";
import api from "../../../api";

export default {
  name: "userAccessView",

  data: () => ({
    state: 'show',
    showError: false,
    errorMessage: "",
    user: null,
    entityType: "Customer",
    selectedDocuments: [],
    DocumentTypes: [],
    entityDocumentList: [],
    entitySearch: "",
    entityList: [],
    selectedEntity: null,
    entity: {
      vendor: [],
      customer: [],
    },
    entityTableData: {
      resultsPerPage: 5,
      currentPage: 1,
      dataSource: [],
      isLoading: true,
      tableColumns: [
        {
          label: 'Name',
          key: 'name',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Code',
          key: 'code',
          sortable: true,
          tdClass: '',
        }
      ]
    },
    isLoading: true,

    accessList:[],
    entityAccess:{
      userId:null,
      enityId:null,
      documentTypes:[]
    }


  }),

  created() {
    var userId = this.$route.params["userId"]

    this.setBreadcrumb([
      {
        text: 'Admin'
      },
      {
        text: 'Document Access'
      },
      {
        text: 'Grant Access'
      },
    ]);
//Get The selected user info
    const s = (data) => {
      this.user = data;
      this.entityAccess.userId=data.Id;
    };

    const e = (msg) => {
      console.log(msg);
      this.makeToast(msg);
    };
    api.getUserById(userId,s,e);

    //get the List of entities
    this.getEntities();

    //get the DocumentTypes available
    this.DocumentTypes = [{text: 'Purchase Order', value: 'PurchaseOrder', entity: "vendor"},
      {text: 'Customer Order', value: 'CustomerOrder', entity: "customer"},
      {text: 'Load Confirmation', value: 'LoadConfirmation', entity: "vendor"},
    ]

    //Get Selected user permissions


  },
  methods: {
    ...mapMutations('breadcrumbService', ['setBreadcrumb']),

    submit() {

      if (!this.completed) {
        return false; // stop here if form is invalid
      }
      const s = () => {
        this.$router.push({path: '/admin/ManageUser/SearchUser'})

      };

      const e = (msg) => {
        console.log("Show Error")

        console.log("Error message", msg);
        this.makeToast(msg)

      };

      // console.log("this.userData", this.userData);
      api.DocumentApplyAccess(this.userData, s, e);

    },
    selectEntity(entity) {
      this.selectedEntity = entity
      this.entityAccess.enityId=entity.Id
    },
    goBackToSearch() {
      this.$router.back()
    },

    changeDocumentList() {
      this.entityList = this.entity[this.entityType.toLowerCase()]
      this.entityTableData.dataSource = this.entityList

      this.entityDocumentList = this.DocumentTypes.filter((x) =>
          x.entity.toLowerCase() === (this.entityType.toLowerCase())
      );

      this.selectedDocuments = []
      this.selectedEntity = null
    },
    makeToast(message) {
      this.$bvToast.toast(`${message}`, {
        title: 'Something went wrong.',
        autoHideDelay: 5000,
        appendToast: false
      })
    },

    getEntities() {
      api.GetVendors(s => {
        this.entity.vendor = s
        this.changeDocumentList();
      }, console.error)
      api.GetCustomers(s => {
        this.entity.customer = s
        this.changeDocumentList();
      }, console.error)

      this.entityTableData.isLoading = false
    },

    searchEntity() {
      this.entityTableData.dataSource = this.entityList.filter((x) =>
          x.name.toLowerCase().includes(this.entitySearch.toLowerCase())
          || x.code.toLowerCase().includes(this.entitySearch.toLowerCase())
      );
    },

  },
  computed: {
    rows() {
      return this.entityTableData.dataSource.length
    },
    completed() {
      return this.user !== null && this.entityType !== null && this.selectedEntity !== null
    }
  },
  watch: {
    entityType() {
      this.changeDocumentList()
    }
  }
}
</script>

<style scoped>

</style>